import { Helmet } from 'react-helmet';
import { queryClient } from '@reibus/frontend-utility';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import App from './App';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  background: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    backgroundColor: '#F6F7F7',
  },
  content: {
    width: 1440,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 48,
    paddingBottom: 48,
  },
});

const Root = () => {
  const { classes } = useStyles();

  return (
    <>
      <Helmet>
        <title>REAMS UI</title>
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <div className={classes.background}>
              <div className={classes.content}>
                <App />
              </div>
            </div>
          </CssBaseline>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
};

export default Root;
