import { create } from 'zustand';

type State = {
  valueId?: string;
  setValueId: (valueId?: string) => void;
  clear: () => void;
};

const useGradeInputStore = create<State>(set => ({
  valueId: undefined,
  setValueId: (valueId: string) => set(() => ({ valueId })),
  clear: () => set(() => ({ valueId: undefined })),
}));

export default useGradeInputStore;
