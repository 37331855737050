import { useQuery } from 'react-query';
import useAttributesClient from './useAttributesClient';

import type { AttributeType } from './types';

const useAttributeValues = (type: AttributeType) => {
  const attributesClient = useAttributesClient();

  return useQuery(
    ['attribute-values', type],
    async (): Promise<{ id: string; value: string }[]> => {
      const { attributes, values } = await attributesClient.attributes.getAll();
      const attribute = attributes.find(attr => attr.name === type);
      return attribute.valueIds.reduce((acc, valueId) => {
        const value = values[valueId];
        acc.push({
          id: valueId,
          value,
        });

        return acc;
      }, []);
    },
    {
      enabled: !!attributesClient,
      cacheTime: Infinity,
    }
  );
};

export default useAttributeValues;
