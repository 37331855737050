import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(45, 108, 203)',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
  },
});

export default theme;
