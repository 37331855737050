import { useEffect } from 'react';
import { useUserSession } from '@reibus/frontend-utility';
import { navigateToUrl } from 'single-spa';
import { CircularProgress } from '@mui/material';
import GradeSearch from './grade-search';

const App = () => {
  const { data: userSession, isFetching } = useUserSession();

  useEffect(() => {
    if (isFetching) {
      return;
    }

    if (!userSession) {
      navigateToUrl('/login?ref=/reams');
    }

    if (!userSession.isReibusUser) {
      navigateToUrl('/app/dashboard');
    }
  }, [userSession, isFetching]);

  if (isFetching) {
    return <CircularProgress />;
  }

  return userSession ? <GradeSearch /> : null;
};

export default App;
