import { Box, Paper } from '@mui/material';
import GradeInput from './GradeInput';
import GradeResults from './GradeResults';

const GradeSearch = () => {
  return (
    <Paper sx={{ borderRadius: '8px', padding: '24px' }}>
      <Box display="flex" flexDirection="column" gap="24px">
        <GradeInput />
        <GradeResults />
      </Box>
    </Paper>
  );
};

export default GradeSearch;
