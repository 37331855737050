import { useMemo } from 'react';

import attributesClient from '@reibus/attributes-api-client-axios';

import { useUserSession } from '@reibus/frontend-utility';

const useAttributesClient = () => {
  const { data: userSession } = useUserSession();

  return useMemo(
    () =>
      userSession
        ? attributesClient({
            environment: process.env.ENVIRONMENT,
            token: userSession.identityToken,
            logger: console,
          })
        : null,
    [userSession]
  );
};

export default useAttributesClient;
