import useGradeDependencies from '@/hooks/useGradeDependencies';
import useGradeInputStore from './useGradeInputStore';
import { CircularProgress, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

const DEP_ORDER = ['Category', 'Format', 'ShapeType', 'Subcategory', 'GradeFamily', 'Grade'];

const useStyles = makeStyles()(theme => ({
  tab: {
    padding: theme.spacing(3),
    height: 450,
    overflowY: 'auto',
  },
}));

type ResultItem = {
  name: string;
  value: string;
  id: string;
};

type ResultsPanelProps = {
  results?: ResultItem[][];
  tabIndex: number;
  currentTab: number;
};

const ResultsPanel = ({ results, tabIndex, currentTab }: ResultsPanelProps) => {
  const { classes } = useStyles();
  const sortedDependencyValues =
    results
      ?.map(dependencySet => {
        const sorted = [...dependencySet]
          .filter(item => item.name !== 'DeploymentRegion')
          .sort((a, b) => {
            return DEP_ORDER.indexOf(a.name) - DEP_ORDER.indexOf(b.name);
          });

        return sorted.map(dependency => dependency.value).join(' > ');
      })
      .sort() ?? [];

  return (
    <div role="tabpanel" hidden={tabIndex !== currentTab} className={classes.tab}>
      {sortedDependencyValues.map(item => (
        <div key={item}>{item}</div>
      ))}
    </div>
  );
};

const GradeResults = () => {
  const [tab, setTab] = useState(0);
  const valueId = useGradeInputStore(state => state.valueId);
  const { data: dependencies, isFetching } = useGradeDependencies(valueId);

  const requiredDependenciesByDeploymentRegion: Record<string, ResultItem[][]> =
    dependencies?.requires.reduce(
      (acc, dependencySet) => {
        const deploymentRegion = dependencySet.find(
          dependency => dependency.name === 'DeploymentRegion'
        );
        acc[deploymentRegion.value].push(dependencySet);
        return acc;
      },
      { NA: [], EU: [] }
    ) ?? {};

  const hasNaResults = requiredDependenciesByDeploymentRegion.NA?.length > 0;
  const hasEuResults = requiredDependenciesByDeploymentRegion.EU?.length > 0;
  const hasResults = hasNaResults || hasEuResults;

  useEffect(() => {
    if (hasNaResults && tab === 1) {
      setTab(0);
    }
  }, [hasNaResults, valueId]);

  useEffect(() => {
    if (!hasNaResults && tab === 0) {
      setTab(1);
    }

    if (!hasEuResults && tab === 1) {
      setTab(0);
    }
  }, [hasNaResults, hasEuResults]);

  if (isFetching) {
    return <CircularProgress />;
  }

  return hasResults ? (
    <div>
      <Tabs value={tab} onChange={(_, newTab: number) => setTab(newTab)}>
        <Tab label="NA" disabled={!hasNaResults} />
        <Tab label="EU" disabled={!hasEuResults} />
      </Tabs>
      <ResultsPanel
        currentTab={tab}
        tabIndex={-1}
        results={requiredDependenciesByDeploymentRegion['NA']}
      />
      <ResultsPanel
        currentTab={tab}
        tabIndex={0}
        results={requiredDependenciesByDeploymentRegion['EU']}
      />
    </div>
  ) : null;
};

export default GradeResults;
