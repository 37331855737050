import useGrades from '@/hooks/useGrades';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useState } from 'react';
import useGradeInputStore from './useGradeInputStore';

const GradeInput = () => {
  const [value, setValue] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');
  const setValueId = useGradeInputStore(state => state.setValueId);
  const { data: grades, isFetching } = useGrades();

  const valueToId =
    grades?.reduce((acc, grade) => {
      acc[grade.value] = grade.id;
      return acc;
    }, {}) ?? {};

  const options = grades?.map(grade => grade.value).sort() ?? [];

  return (
    <Autocomplete
      loading={isFetching}
      autoFocus
      value={value}
      onChange={(event: any, newValue: string | null) => {
        setValue(newValue);
        setValueId(valueToId[newValue]);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      sx={{ width: 350 }}
      size="small"
      renderInput={params => <TextField {...params} autoFocus label="Grade" />}
      noOptionsText="Grade not found"
      autoHighlight
    />
  );
};

export default GradeInput;
