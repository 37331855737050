import { useQuery } from 'react-query';
import useAttributesClient from './useAttributesClient';

import type { AttributeType } from './types';

const useValueDependencies = (id: string | undefined, type: AttributeType) => {
  const attributesClient = useAttributesClient();

  return useQuery(
    ['dependencies', type, id],
    () => {
      return attributesClient.attributes.values.dependencies.get({
        parameters: {
          id,
          name: type,
        },
      });
    },
    {
      enabled: !!attributesClient && !!id,
      cacheTime: Infinity,
    }
  );
};

export default useValueDependencies;
